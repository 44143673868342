.pad {
    display:flex;
    flex-direction: column;
    align-items: center;
    margin:5px;
    
    border: solid 2px #fff;
    border-bottom: none;
    font-family: gantic;
    min-width: 90px;
}

.pad-knobs {
    padding:10px;
}

.pad-label {
    color:black;
    background-color: #fff;
    width:100%;
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;
    line-height: 0.9em;
    position: relative;
    top:0px;
    font-family: coolveticarg;
}

.pad-button {
    width:60px;
    height:60px;
    margin-bottom: 10px;
    border-radius: 3px;
    background-color: #c7cfd5;
    
}

.pad-button.active {
    background-color: palevioletred;
    border-color: rebeccapurple;

}