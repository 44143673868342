.knob-text {
    color:#C0C0C0;
    text-shadow: 0px 0px 5px #fff;
    /* font-size:0.6em; */
    
}

.knob-wrapper {
    display:flex;
    flex-direction: column;
    align-items: center;
}