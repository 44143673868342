.App {
  height:100vh;
  width:100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #112528;

}

.header {
  color:#fff;
  font-family: Stereofunk;
  font-size: 3em;
}

.deck-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
}

.deck {
  background-image: url('Images/DrumKitBack.png');
  background-repeat: no-repeat;
  background-size: cover;
  padding:20px 50px;
  border-bottom: solid 2px black;
}
